import StatsCard from "../cards/StatsCard";

//SCSS
import v from "../../scss/helpers/_export-theme-variables.scss";

const SubscriptionTab = ({ monthlyRevenues }) => {
  console.log(monthlyRevenues);
  return (
    <div className="revenue__summary-reports__content">
      <div className="row">
        <div className="col-12 col-md-4">
          <StatsCard
            bgColor={v.colorgrey60}
            title={"عدد دقائق المشاهدة"}
            num={monthlyRevenues?.total_watched_units}
            numFontSize={24}
            numClassName="mb-0"
            containerClassName="h-auto px-21 py-22"
            text="دقيقة"
          />
        </div>
        <div className="col-12 col-md-4">
          <StatsCard
            bgColor={v.colorgrey60}
            title="إجمالي الربح "
            num={monthlyRevenues?.total_subscription_in_month}
            numFontSize={24}
            numClassName="mb-0"
            containerClassName="h-auto px-21 py-22"
            text="د.ك"
          />
        </div>
        <div className="col-12 col-md-4">
          <StatsCard
            bgColor={v.colorgrey60}
            title=" ربح الاشتراكات"
            num={monthlyRevenues?.subscription_revenue}
            text="د.ك"
            numFontSize={24}
            numClassName="mb-0"
            containerClassName="h-auto px-21 py-22"
            textFontSize={16}
            textFontWeight="font-bold"
          ></StatsCard>
        </div>
        <div className="col-12 col-md-4 my-3">
          <StatsCard
            bgColor={v.colorgrey60}
            title=" ربح المشاركة"
            num={monthlyRevenues?.subscription_affiliate}
            numFontSize={24}
            numClassName="mb-0"
            containerClassName="h-auto px-21 py-22"
            text="د.ك"
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTab;
